export const useLocalStorage = () => {
    const getFromStorage = (key: string) => {
        const items = localStorage.getItem(key)
        if (items) {
            return JSON.parse(items)
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const saveToStorage = (key: string, data: any) => {
        localStorage.setItem(key, JSON.stringify(data))
    }

    const removeFromStorage = (key: string) => {
        localStorage.removeItem(key)
    }

    return {
        saveToStorage,
        getFromStorage,
        removeFromStorage,
    }
}
