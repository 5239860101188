import { CustomInputPropsInterface } from 'src/interfaces'
import { useUI } from 'src/state/uiContext'

import { Input as AntInput } from 'antd'

export default function Input(props: CustomInputPropsInterface) {
    const { title, errorMessage, className, type = 'default', ...restProps } = props
    const { colors } = useUI()

    const renderSwitch = (type: string) => {
        switch (type) {
            case 'password':
                return <AntInput.Password {...restProps} />
            default:
                return <AntInput {...restProps} />
        }
    }

    return (
        <div className={`flex w-full flex-col ${className}`}>
            {title && <span className="font-semibold">{title}</span>}
            {renderSwitch(type)}
            {errorMessage && <span style={{ color: colors.error }}>{errorMessage}</span>}
        </div>
    )
}
