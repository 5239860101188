import React, { useEffect, lazy, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import useSyncRequestListener from 'src/auth/hooks/useSyncRequestListener'

import { useUser } from '../../state/userContext'
import {routes} from '../routes'
import { getRoutes } from './routeRegistry'


const Root = lazy(() => import('src/routes/root'))


export default function Protected() {
    const { t } = useTranslation()
    const location = useLocation()
    const { authenticated } = useUser()

    // Listen for sync requests from other tabs.
    useSyncRequestListener()

    const appRoutes = useMemo(() => getRoutes(), [])


    useEffect(() => {
        if (!authenticated) {
            toast.info(t('You need be logged in to see the chosen page.'))
        }
    }, [authenticated])

    if (authenticated) {
        return <Root appRoutes={appRoutes} />
    }

    return <Navigate to={routes.LOGIN} state={{ previousRoute: location?.pathname }} />
}
