import { lazy } from 'react'
import { Route } from 'react-router-dom'

import { routes } from '../routes'
import { registerRoute } from '../utils/routeRegistry'

const ModuleWrapper = lazy(() => import('./containers/ModuleWrapper'))

export default () => {
    registerRoute(<Route index path={`${routes.RULES}/*` } element={ <ModuleWrapper moduleUrl={ routes.RULES_MODULE } /> } />);
    registerRoute(<Route path={ routes.HOME + '/*' } element={ <ModuleWrapper moduleUrl={ routes.HOME_MODULE } /> } />);
}
