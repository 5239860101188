import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import Alert from 'src/components/atoms/alert'
import Button from 'src/components/atoms/button'
import FormItem from 'src/components/molecules/formItem'
import { LoginFormPropsInterface } from 'src/interfaces'
import { routes } from 'src/routes/routes'

import { Form } from 'antd'

export default function LoginForm({ onSubmit, submitting, error }: LoginFormPropsInterface) {
    const location = useLocation()
    const { t } = useTranslation()
    const [username, setUsername] = useState<string>(location?.state?.username ? location.state.username : '')
    const [password, setPassword] = useState<string>('')

    useEffect(() => {
        setUsername(location?.state?.username ? location.state.username : '')
    }, [location])

    const handleOnFinish = () => onSubmit(username, password)

    return (
        <div data-testid="login-form" className="w-full">
            <Form layout="vertical" onFinish={handleOnFinish} className="w-full" initialValues={{ username, password }}>
                <div className="mb-6">
                    <FormItem
                        validationRules={[{ required: true, message: t('Please add username or e-mail') }]}
                        labelText={t('E-mail or username')}
                        name="username"
                        value={username}
                        isLoading={submitting}
                        handleChange={setUsername}
                        isFocused={true}
                        placeholder={t('Enter username or e-mail')}
                    />
                </div>

                <FormItem
                    validationRules={[{ required: true, message: t('Please add password') }]}
                    labelText={t('Password')}
                    name="password"
                    isLoading={submitting}
                    value={password}
                    handleChange={setPassword}
                    placeholder={t('Enter your password')}
                    type="password"
                />

                {error ? (
                    <Alert
                        message={t('There is no user found with the given credentials.')}
                        type="error"
                        data-testid="error-login"
                        className="mt-4"
                    />
                ) : null}

                <Form.Item className="mb-0 mt-6">
                    <Button htmlType="submit" type="primary" block disabled={submitting} loading={submitting}>
                        {t('Log in', 'Log in')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
