import i18n from 'src/i18n'
import { API_ENDPOINTS } from 'src/constants'
import { getFromStorage } from 'src/shared/helpers/sessionStorageHelpers'
import { getCookie } from 'src/utils'

import { InvalidAccessTokenError } from '../../constants'
import api from '../api'

import { AxiosRequestConfig } from 'axios'

const fetchRequest = async <T extends {}>(url: string, options: Partial<AxiosRequestConfig> = {}): Promise<T> => {
    const { accessToken } = getFromStorage('user')

    // backend framework expects X-CSRFToken in the headers instead of cookies
    const CSRFToken = getCookie('csrftoken')
    const appendCSRFTOKEN = options?.method === 'POST' && CSRFToken

    const appendAccessToken = !!accessToken && url !== API_ENDPOINTS.REFRESH_TOKEN

    const bodyContainsFormData = isFormData(options.data) || Object.values(options.data ?? {}).some(isFormData)
    const JSONContentType = options.data && !bodyContainsFormData

    const requestOptions: AxiosRequestConfig = {
        ...options,
        headers: {
            'Accept-Language': i18n.language,
            ...(appendCSRFTOKEN ? { 'X-CSRFToken': CSRFToken } : {}),
            ...(JSONContentType ? { 'Content-Type': 'application/json' } : {}),
            ...(appendAccessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
            ...options.headers,
        },
    }

    try {
        const response = await api(url, requestOptions)

        if (response.status === 204) {
            return null
        }

        if (response.status >= 200 && response.status < 300) {
            const contentType = response.headers['content-type']
            const JSONResponse = contentType?.includes('application/json')
            return JSONResponse ? response.data : undefined
        }

        if (response.status === 401 || response.status === 403) {
            throw new InvalidAccessTokenError()
        }

        throw new Error('An error occurred. Fetch request not executed.')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        if (err instanceof InvalidAccessTokenError) {
            throw err
        }

        throw new Error(err.message ?? 'An error occurred. Fetch request not executed.')
    }
}

const isFormData = (value: unknown): value is FormData => value instanceof FormData

export default fetchRequest
