import { SESSION_STORAGE_EVENT } from 'src/hooks/useSessionStorageListener'
import {
    getFromStorage as getFromSessionStorage,
    saveToStorage as saveToSessionStorage,
} from 'src/shared/helpers/sessionStorageHelpers'

export const crossTabChannel = new BroadcastChannel('share-session')

const syncSession = async () => {
    return new Promise((resolve) => {
        const { authenticated: authenticatedInOtherTab } = JSON.parse(localStorage.getItem('user') || '{}')

        if (!authenticatedInOtherTab) {
            resolve({})
        }

        const session = getFromSessionStorage('user')
        const sessionNeeded = !session.hasOwnProperty('accessToken')

        if (sessionNeeded) {
            // Requesting a session from another tab
            crossTabChannel.postMessage({ request: 'sync' })

            // Listening for a response from another tab
            crossTabChannel.onmessage = (e) => {
                if (e.data.response !== 'sync') {
                    return
                }

                const { session } = e.data
                saveToSessionStorage('user', session)
            }

            // If the session is not received in 1 second, resolve the promise
            // This results in the user being redirected to the login page
            const timeout = setTimeout(() => resolve({}), 1000)

            // Once the session storage is updated, resolve the promise
            window.addEventListener(SESSION_STORAGE_EVENT, () => {
                const currentSession = getFromSessionStorage('user')
                if (currentSession.hasOwnProperty('accessToken')) {
                    clearTimeout(timeout)
                    resolve({})
                }
            })
        } else {
            resolve({})
        }
    })
}

export default syncSession
