import { initReactI18next } from 'react-i18next'

import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, DEVELOPMENT_MODE } from './settings'
import commonEN from './translations/en/common.json'
import commonFR from './translations/fr/common.json'
import commonNL from './translations/nl/common.json'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

export const defaultNS = 'common'
export const resources = {
    en: {
        common: commonEN,
    },
    nl: {
        common: commonNL,
    },
    fr: {
        common: commonFR,
    },
} as const

i18n.use(LanguageDetector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        defaultNS,
        fallbackLng: DEFAULT_LANGUAGE,
        supportedLngs: AVAILABLE_LANGUAGES,
        debug: DEVELOPMENT_MODE,
        returnEmptyString: false,
    })

export default i18n
