import { useEffect, useState } from 'react'

export const SESSION_STORAGE_EVENT = 'session-storage'

const useSessionStorageListener = (key: string) => {
    const [value, setValue] = useState(null)

    const getValue = () => {
        const items = window.sessionStorage.getItem(key)
        setValue(items ? JSON.parse(items) ?? {} : {})
    }

    useEffect(() => getValue(), [])

    useEffect(() => {
        const handleStorage = () => getValue()
        window.addEventListener(SESSION_STORAGE_EVENT, handleStorage)
        return () => window.removeEventListener(SESSION_STORAGE_EVENT, handleStorage)
    }, [key])

    return value ?? {}
}

export default useSessionStorageListener
