import React, { useEffect, useState } from 'react'

import Input from 'src/components/atoms/input'
import { FormItemPropsInterface } from 'src/interfaces'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'antd'

export default function FormItem(props: FormItemPropsInterface) {
    const {
        isLoading,
        value,
        handleChange,
        isFocused = false,
        type = 'default',
        icon,
        labelText,
        name,
        dependencies,
        validationRules,
        placeholder,
    } = props

    const [inputValue, setInputValue] = useState(value)

    useEffect(() => {
        setInputValue(inputValue)
    }, [value])

    return (
        <Form.Item
            label={labelText ? <span className="text-sm">{labelText}</span> : ''}
            dependencies={dependencies}
            rules={validationRules}
            wrapperCol={{ className: '-mt-2' }}
            className="mb-2"
            hasFeedback
            validateStatus={isLoading ? 'validating' : ''}
            name={name}
        >
            <Input
                size="large"
                autoFocus={isFocused}
                prefix={icon ? <FontAwesomeIcon icon={icon} className="mr-1 text-gray-400" /> : null}
                type={type}
                name={name}
                data-testid={`test-${name}`}
                disabled={isLoading}
                placeholder={placeholder}
                onChange={(e) => handleChange(e.target.value)}
                value={inputValue}
            />
        </Form.Item>
    )
}
