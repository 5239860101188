import { useNavigate } from 'react-router-dom'

import { routes } from 'src/routes/routes'
import { removeFromStorage } from 'src/shared/helpers/sessionStorageHelpers'
import { UserActionKind, useUserDispatch } from 'src/state/userContext'

const { LOG_OUT } = UserActionKind

const useLogout = () => {
    const navigate = useNavigate()
    const userDispatch = useUserDispatch()

    const handleLogout = () => {
        removeFromStorage('user')
        userDispatch({ type: LOG_OUT })
        navigate(routes.LOGIN)
    }

    return {
        onLogOut: handleLogout,
    }
}

export default useLogout
