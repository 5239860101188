//*************************
//********** GENERAL ********
//*************************

export const DEFAULT_LANGUAGE = 'en'
export const AVAILABLE_LANGUAGES: Array<string> = ['en', 'nl', 'fr']

export const LANGUAGES: { [languageCode: string]: { nativeName: string } } = {
    en: { nativeName: 'English' },
    nl: { nativeName: 'Nederlands' },
    fr: { nativeName: 'Français' },
}

export const DEVELOPMENT_MODE = false
