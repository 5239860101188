interface Registry<T> {
    [key: string]: T
}

const createRegistry = <T>() => {
    const registry: Registry<T> = {}

    const add = (key: string, value: T) => {
        const lowerCaseKey = key.toLowerCase()

        if (registry[lowerCaseKey]) {
            throw new Error(`Registry key ${key} already exists`)
        }

        registry[lowerCaseKey] = value
    }

    const get = (key: string) => {
        const lowerCaseKey = key.toLowerCase()

        return registry[lowerCaseKey] || undefined
    }

    const remove = (key: string) => {
        const lowerCaseKey = key.toLowerCase()

        delete registry[lowerCaseKey]
    }

    const keys = () => Object.keys(registry)

    return { add, get, remove, keys }
}

export default createRegistry
