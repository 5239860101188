import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useAPIRequest from 'src/hooks/useAPIRequest'
import { routes } from 'src/routes/routes'
import { getFromStorage, saveToStorage } from 'src/shared/helpers/sessionStorageHelpers'
import { UserActionKind, useUser, useUserDispatch } from 'src/state/userContext'

import apiClient from './apiClient'
import { isMFAResponse } from './typeguards'
import { LoginResponse } from './types'

const { LOG_IN, SET_USER_INFO } = UserActionKind

const useLogin = () => {
    const userDispatch = useUserDispatch()
    const { authenticated, info } = useUser()
    const { id: userId } = info

    const location = useLocation()
    const navigate = useNavigate()

    const { partialAuthToken } = getFromStorage('user')

    const { callAPI: getUserInfo, result: userInfo } = useAPIRequest(apiClient.getUserInfo)
    
    const {
        callAPI: loginCredentialUser,
        result: credentialLogin,
        loading: loggingInCredentialUser,
        error: loginCredentialUserError,
        reset: resetCredentialLogin,
    } = useAPIRequest(apiClient.loginCredentialUser)
    

    const resetAllAPIRequests = () => {
        resetCredentialLogin()
    }

    const loading =  loggingInCredentialUser 
    const errors = { loginCredentialUserError }

    const handleLogin = (loginData: LoginResponse) => {
        const currentSessionState = getFromStorage('user') ?? {}
        saveToStorage('user', { ...currentSessionState, ...loginData })
        if (!isMFAResponse(loginData)) {
            userDispatch({ type: LOG_IN })
        }
    }

    useEffect(() => {
        if (credentialLogin) {
            handleLogin(credentialLogin)
        }
    }, [credentialLogin])

    useEffect(() => {
        const needUserData = authenticated && !userId
        if (needUserData) {
            getUserInfo({})
        }
    }, [authenticated, userId])

    useEffect(() => {
        if (authenticated && userInfo) {
            userDispatch({ type: SET_USER_INFO, payload: userInfo })

            navigate(routes.RULESOVERVIEW, { ...(location.state ? { state: location.state } : {}) })
        }
    }, [authenticated, userInfo])

   

    const handleCredentialLogin = async (username: string, password: string) => {
        resetAllAPIRequests()
        await loginCredentialUser({ username, password })
    }

    return {
        errors,
        loading,
        onLogin: handleCredentialLogin,
    }
}

export default useLogin
