import { fetchRequest } from 'src/hooks/useAPIRequest'
import { API_ENDPOINTS } from 'src/constants'

import { LoginResponse } from '../../types'

interface Params {
    username: string
    password: string
}

const loginCredentialUser = async ({ username, password }: Params) => {
    return await fetchRequest<LoginResponse>(API_ENDPOINTS.LOGIN, {
        method: 'POST',
        data: JSON.stringify({ username, password }),
    })
}

export default loginCredentialUser
