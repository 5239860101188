const apiStartingUrl = `${process.env.REACT_APP_DOMAIN}/api`

const authApiEndpoints = {
    LOGIN: `${apiStartingUrl}/auth/login`,
    REFRESH_TOKEN: `${apiStartingUrl}/auth/refresh`,
}


const uploadApiEndpoints = {
    UPLOAD_IMAGE: `${apiStartingUrl}/images/upload`,
    UPLOAD_IMAGES: `${apiStartingUrl}/images/upload-multiple`,
}

const adminApiEndpoints = {
    RULES_SCHEMAS: `${apiStartingUrl}/admin/schemas/list`,
    RULES_ONE_SCHEMA: `${apiStartingUrl}/admin/schemas/`,
}

export const API_ENDPOINTS = {
    USER_INFO: `${apiStartingUrl}/auth/me`,
    ADMIN: `${apiStartingUrl}/admin`,
    ...authApiEndpoints,
    ...uploadApiEndpoints,
    ...adminApiEndpoints,
}

export const TOAST_CONTAINER_ID = 'ToastContainer'
