import React from 'react'

import createRegistry from 'src/core/packages/registryFactory'

type RouteComponent = React.ReactElement<{ path: string | string[] }>

const createRouteRegistry = () => {
    const registry = createRegistry<RouteComponent>()

    const registerRoute = (routeComponent: RouteComponent) => {
        if (!Array.isArray(routeComponent.props.path)) {
            registry.add(routeComponent.props.path, routeComponent)
            return
        }

        routeComponent.props.path.forEach((path) => {
            registry.add(path, routeComponent)
        })

        return
    }

    const getRoutes = () => {
        return registry.keys().map((key: any) => {return React.cloneElement(registry.get(key) as RouteComponent, { key })})
    }

    return { registerRoute, getRoutes }
}

const routeRegistry = createRouteRegistry()

export const registerRoute = routeRegistry.registerRoute
export const getRoutes = routeRegistry.getRoutes
