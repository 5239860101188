import { useCallback, useState } from 'react'

import { removeFromStorage } from 'src/shared/helpers/sessionStorageHelpers'
import { UserActionKind, useUserDispatch } from 'src/state/userContext'

import { InvalidAccessTokenError } from './constants'
import useLogout from '../useLogout'

const { LOG_OUT } = UserActionKind

const useAPIRequest = <T, S>(apiRequest: (props: T) => Promise<S>) => {
    const userDispatch = useUserDispatch()
    const logout = useLogout();

    const [error, setError] = useState<boolean>(false)
    const [result, setResult] = useState<S>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const reset = () => {
        setError(false)
        setResult(undefined)
        setLoading(false)
        setSuccess(false)
    }

    const errorHandler = useCallback((err: Error) => {
        console.log(err);
        if (err instanceof InvalidAccessTokenError) {
            logout.onLogOut();
        }

        setError(true)
        setResult(undefined)
        setSuccess(false)
    }, [logout])

    const callAPI = useCallback(async (props: T = {} as T): Promise<S|void> => {
        setLoading(true)
        setError(false)
        setSuccess(false)

        return await apiRequest(props)
            .then((data) => {
                setResult(data)
                setError(undefined)
                setSuccess(true)
                return data;
            })
            .catch(errorHandler)
            .finally(() => {
                setLoading(false)
            })
    }, [apiRequest, errorHandler])

    return { callAPI, result, error, success, loading, reset }
}

export default useAPIRequest
