import { useEffect } from 'react'

import { getFromStorage, removeFromStorage } from 'src/shared/helpers/sessionStorageHelpers'
import { UserActionKind, useUserDispatch } from 'src/state/userContext'

import { crossTabChannel } from '../../syncSession'

const { LOG_OUT } = UserActionKind

const useSyncRequestListener = () => {
    const userDispatch = useUserDispatch()

    useEffect(() => {
        crossTabChannel.onmessage = (e) => {
            const session = getFromStorage('user')

            if (e.data.request === 'logout' && session.hasOwnProperty('accessToken')) {
                removeFromStorage('user')
                userDispatch({ type: LOG_OUT })
                return
            }

            if (e.data.request !== 'sync' || !session.hasOwnProperty('accessToken')) {
                return
            }

            crossTabChannel.postMessage({ session, response: 'sync' })
        }
    }, [])
}

export default useSyncRequestListener
