// File used for reusable utility functions
import { format, parseISO } from 'date-fns'
import Cookies from 'js-cookie'

export const getFirstLetter = (text: string) => {
    return text.charAt(0)
}

export const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export const encodeUrl = (url: string | number) => {
    // encodeURIComponent - escapes all chars except A–Z a–z 0–9 - _ . ! ~ * ' ( )
    // replace -> escapes next chars . ! ~ * ' ( )
    return encodeURIComponent(url.toString()).replace(/[.!~*'()]/g, (c) => `%${c.charCodeAt(0).toString(16)}`)
}

export const buildUrl = (endpoint: string, params?: { [key: string]: string | number }): string => {
    let url = endpoint

    if (params && endpoint.includes(':')) {
        Object.keys(params).forEach((key) => {
            const encodedValue = encodeUrl(params[key])
            url = url.replace(`:${key}`, encodedValue)
        })
    } else if (endpoint.includes(':')) {
        throw new Error(`Missing required parameter for endpoint: ${endpoint}`)
    }
    return url
}

export const formatDatetime = (datetime: string, returnFormat: 'date' | 'datetime' = 'date') => {
    if (!datetime) {
        return ''
    }

    let formatting = ''
    switch (returnFormat) {
        case 'date':
            formatting = 'dd/MM/yyyy'
            break
        case 'datetime':
            formatting = 'dd/MM/yyyy - HH:MM'
    }

    return format(parseISO(datetime), formatting)
}

export const getCookie = (cookieName: string) => {
    return Cookies.get(cookieName)
}
