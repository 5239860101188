import AuthTemplate from 'src/components/atoms/authTemplate'
import useLogin from 'src/hooks/useLogin'
import { LoginForm } from 'src/routes/login'


export default function Login() {

    const { loading, errors, onLogin } = useLogin()


    return (
        <AuthTemplate>
            
                <>
                    <LoginForm
                        submitting={loading}
                        error={errors.loginCredentialUserError}
                        onSubmit={onLogin}
                    />
                </>
            
        </AuthTemplate>
    )
}
