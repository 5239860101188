import React, { createContext, Dispatch, useContext, useReducer } from 'react'

import { useLocalStorage } from 'src/hooks/useLocaleStorage'
import { BusinessUnit, BusinessUnitBrand, Dealer, UserInfo, UserRole } from 'src/interfaces'

import { getFromStorage as getFromSessionStorage } from '../shared/helpers/sessionStorageHelpers'

import { v4 } from 'uuid'

export const UserContext = createContext<UserState | undefined>(undefined)
export const UserDispatchContext = createContext<Dispatch<UserAction> | undefined>(undefined)

export function UserProvider({ children }: { children: React.ReactNode }) {
    const { getFromStorage: getFromLocalStorage } = useLocalStorage()

    const session = getFromSessionStorage('user') || {}
    const activeSession = Object.keys(session).length > 0

    const finalInitialUserState = activeSession ? getFromLocalStorage('user') : initialUserState
    const [user, dispatch] = useReducer(UserReducer, finalInitialUserState)
    return (
        <UserContext.Provider value={user}>
            <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
        </UserContext.Provider>
    )
}

export function UserReducer(UserState: UserState | never, action: UserAction): UserState | never {
    const {
        getFromStorage: getFromLocalStorage,
        saveToStorage: saveToLocalStorage,
        removeFromStorage: removeFromLocalStorage,
    } = useLocalStorage()

    switch (action.type) {
        case UserActionKind.INIT: {
            const state = getFromLocalStorage('user') || {}
            return { ...UserState, ...state }
        }
        case UserActionKind.LOG_IN: {
            const newLocalState = { ...UserState, authenticated: true, unAuthorized: false }

            saveToLocalStorage('user', newLocalState)

            return newLocalState
        }
        case UserActionKind.SET_USER_INFO: {
            const { id, username, firstName, lastName, roles } = action.payload

                       
            const newState = {
                ...UserState,
                info: { ...UserState.info, id, username, firstName, lastName },
                roles,
            }

            saveToLocalStorage('user', newState)

            return newState
        }
        case UserActionKind.SET_DEALER: {
            const newState = {
                ...UserState,
                dealer: action.payload.dealer,
            }

            saveToLocalStorage('user', newState)

            return newState
        }
        case UserActionKind.SET_BUSINESS_UNIT: {
            const newState = {
                ...UserState,
                businessUnit: action.payload.businessUnit,
                businessUnitBrand: initialUserState.businessUnitBrand,
            }

            saveToLocalStorage('user', newState)

            return newState
        }
        case UserActionKind.SET_BUSINESS_UNIT_BRAND: {
            const newState = {
                ...UserState,
                businessUnitBrand: action.payload.businessUnitBrand,
            }

            saveToLocalStorage('user', newState)

            return newState
        }

        case UserActionKind.LOG_OUT: {
            const { unAuthorized } = action.payload || {}

            removeFromLocalStorage('user')

            return {
                ...UserState,
                authenticated: false,
                unAuthorized,
                info: { ...initialUserState.info },
            }
        }

        case UserActionKind.CANCEL_LOG_IN: {
            return {
                ...UserState,
                authenticated: false,
                info: { ...initialUserState.info },
            }
        }

        default: {
            throw Error('Unknown action: ' + action.type)
        }
    }
}

// An enum with all the types of actions to use in our reducer
export enum UserActionKind {
    INIT = 'INIT',
    LOG_IN = 'LOG_IN',
    LOG_OUT = 'LOG_OUT',
    SET_USER_INFO = 'SET_USER_INFO',
    SET_DEALER = 'SET_DEALER',
    CANCEL_LOG_IN = 'CANCEL_LOG_IN',
    SET_BUSINESS_UNIT = 'SET_BUSINESS_UNIT',
    SET_BUSINESS_UNIT_BRAND = 'SET_BUSINESS_UNIT_BRAND',
}

interface Payload {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}
interface UserAction {
    type: UserActionKind
    payload?: Payload
}

export type UserState = {
    id: string
    authenticated: boolean
    unAuthorized: boolean
    info: UserInfo
    roles: UserRole[]
    dealer: Dealer
    businessUnit: BusinessUnit
    businessUnitBrand: BusinessUnitBrand
}

// TODO initialize this differently
export const initialUserState: UserState = {
    id: v4(),
    authenticated: false,
    unAuthorized: false,
    info: {
        id: null,
        username: '',
        firstName: '',
        lastName: '',
        dealerId: null,
    },

    roles: [],

    dealer: {
        id: null,
        name: '',
        address1: '',
        address2: '',
        postalCode: '',
        city: '',
        countryCode: '',
        email: '',
        telephone: '',
        mobile: '',
        fax: '',
    },

    businessUnit: {
        id: null,
        name: '',
        address1: '',
        address2: '',
        postalCode: '',
        city: '',
        countryCode: '',
        email: '',
        telephone: '',
        mobile: '',
        fax: '',
        dealer_id: 1,
    },
    businessUnitBrand: {
        id: null,
        name: '',
    },
}

export function useUser() {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error('UserContext must be within provider')
    }
    return context
}

export function useUserDispatch() {
    const context = useContext(UserDispatchContext)
    if (context === undefined) {
        throw new Error('UserDispatchContext must be within provider')
    }
    return context
}
