import React, { createContext, Dispatch, useContext, useReducer } from 'react'

import chroma from 'chroma-js'
import { Application } from 'src/interfaces'

import { App, ConfigProvider } from 'antd'

export const UIContext = createContext<UIState | undefined>(undefined)
export const UIDispatchContext = createContext<Dispatch<UIAction> | undefined>(undefined)

export function UIProvider({ children }: { children: React.ReactNode }) {
    const [ui, dispatch] = useReducer(UIReducer, initialUIState)

    return (
        <UIContext.Provider value={ui}>
            <UIDispatchContext.Provider value={dispatch}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: ui.colors.primary,
                            fontSize: 12,
                        },
                    }}
                    componentSize="small"
                    space={{ size: 'small' }}
                >
                    <App>{children}</App>
                </ConfigProvider>
            </UIDispatchContext.Provider>
        </UIContext.Provider>
    )
}

export function UIReducer(UIState: UIState | never, action: UIAction): UIState | never {
    switch (action.type) {
        case UIActionKind.SET_ACTIVE_APP: {
            return {
                ...UIState,
                active_app: action.payload.id,
            }
        }
        case UIActionKind.REMOVE_BUSINESS_UNIT: {
            return {
                ...UIState,
            }
        }
        default: {
            throw Error('Unknown action: ' + action.type)
        }
    }
}
// An enum with all the types of actions to use in our reducer
export enum UIActionKind {
    ERRORTEST = 'ERRORTEST',
    SET_ACTIVE_APP = 'SET_ACTIVE_APP',
    REMOVE_BUSINESS_UNIT = 'REMOVE_BUSINESS_UNIT',
}

interface Payload {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}
interface UIAction {
    type: UIActionKind
    payload: Payload
}

export type UIState = {
    assets: {
        logo: string
        logoLight: string
    }
    colors: {
        primary: string
        primaryBackground: string
        primaryBackgroundLight: string
        error: string
        success: string
        subtle: string
    }
    active_app: number | string
    apps: Application[]
}

const PRIMARY_COLOR = '#1677ff'
// TODO initialize this differently
export const initialUIState: UIState = {
    assets: {
        logo: 'https://expert4-application.autralis.com/autralis-logo.png',
        logoLight: 'https://expert4-application.autralis.com/autralis-logo-light.png',
    },
    colors: {
        primary: PRIMARY_COLOR,
        primaryBackground: chroma.mix(PRIMARY_COLOR, 'white', 0.2).css(),
        primaryBackgroundLight: chroma.mix(PRIMARY_COLOR, 'white', 0.92).css(),
        error: 'red',
        success: 'green',
        subtle: '#C6C6C6',
    },
    active_app: 1,
    apps: [
        {
            id: 1,
            type: 'rules',
            name: 'Rules',
            baseUrl: '/rules',
            modules: [
                {
                    key: 'overview',
                    name: 'Overview',
                    icon: 'faRulerCombined',
                    path: '/overview',
                    menuItems: [
                    ],
                },
            ] 
        }
    ],
}

export function useUI() {
    const context = useContext(UIContext)
    if (context === undefined) {
        throw new Error('UIContext must be within provider')
    }
    return context
}

export function useUIDispatch() {
    const context = useContext(UIDispatchContext)
    if (context === undefined) {
        throw new Error('UIDispatchContext must be within provider')
    }
    return context
}
