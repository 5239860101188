import { SESSION_STORAGE_EVENT } from 'src/hooks/useSessionStorageListener'

export const getFromStorage = (key: string) => {
    const items = sessionStorage.getItem(key)
    if(items) {
        return JSON.parse(items) ?? {}
    }
    return {};
}

export const saveToStorage = (key: string, data: unknown) => {
    sessionStorage.setItem(key, JSON.stringify(data))
    dispatchCustomEvent()
}

export const removeFromStorage = (key: string) => {
    sessionStorage.removeItem(key)
    dispatchCustomEvent()
}

// Dispatch a custom even that is being used in "useSessionStorageListener" hook
const dispatchCustomEvent = () => window.dispatchEvent(new Event(SESSION_STORAGE_EVENT))
