import initRoutes from './routes/initRoutes'

import syncSession from './auth/syncSession'

// Make sure that a session is loaded before starting the application.
// This prevents the user of needing to log in for each browser tab.
syncSession().then(() => {
    initRoutes().then(() => {
        // Browser is polyfilled, start application.
        require('./start')
    })
})