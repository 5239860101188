import ReactDOM from 'react-dom/client'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Login from 'src/routes/login'
import { routes } from 'src/routes/routes'
import Protected from 'src/routes/utils/protected'

import { TOAST_CONTAINER_ID } from './constants'
import './i18n'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { UserProvider } from './state/userContext'
import { UIProvider } from './state/uiContext'

const App = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path={routes.LOGIN} element={<Login />} />
                <Route path="/*" element={<Protected />} />
            </>,
        ),
    )

    // Reduce the delay before a toast is automatically closed when running tests, to make sure they interfere with
    // the test instructions.
    const toastAutoClose = process.env.NODE_ENV === 'test' ? 1000 : undefined

    return (
        <UIProvider>
            <UserProvider>
                <RouterProvider router={router} />
                <ToastContainer containerId={TOAST_CONTAINER_ID} autoClose={toastAutoClose} />
            </UserProvider>
        </UIProvider>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
