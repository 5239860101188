import { API_ENDPOINTS } from 'src/constants'
import { getFromStorage, saveToStorage } from 'src/shared/helpers/sessionStorageHelpers'

import { LoggedInResponse } from '../../../useLogin/types'
import { InvalidAccessTokenError } from '../../constants'
import fetchRequest from '../fetchRequest'

import axios, { AxiosError } from 'axios'

const api = axios.create({})

api.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        if (error.response?.status === 401) {
            if (error.config.url.includes(API_ENDPOINTS.REFRESH_TOKEN)) {
                throw new InvalidAccessTokenError()
            }

            const { accessToken, refreshToken } = getFromStorage('user')
            if (refreshToken) {
                const result = await fetchRequest<LoggedInResponse>(API_ENDPOINTS.REFRESH_TOKEN, {
                    method: 'POST',
                    data: JSON.stringify({ accessToken, refreshToken }),
                })

                saveToStorage('user', result)
                error.config.headers.Authorization = `Bearer ${result.accessToken}`
                return api(error.config)
            }
        }

        return error
    },
)

export default api
